import AOS from 'aos';
import "aos/dist/aos.css";
import React, { useEffect } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import Contact from './components/Contact';
import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';


import Faq from './components/faq';
import './index.css';
import Home from './pages/Home';



import { I18nextProvider } from 'react-i18next';
import Layout from './components/layout';
import i18n from './i18n';



function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("REVODEVAC");


 



  return (
    <>
       <I18nextProvider i18n={i18n}>
      <Router>
        <ScrollToTop>
          <Layout>
         <Routes>
            <Route path="/" element={<Home  />} />
            <Route path="/contact" element={<Contact  />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/whyrevodeac" element={<whyrevodeac  />} /> 
           </Routes> 
          </Layout>
          </ScrollToTop>
      </Router>
      </I18nextProvider>
    </>
  );
}


export default App;