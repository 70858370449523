import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

const Faq = forwardRef((props, ref) => {
    const { t, i18n } = useTranslation();
    const index = i18n.language;

    return (
        <div ref={ref} dir={index === 'ar' ? 'rtl' : 'ltr'}>
            <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-8 mx-auto" data-aos="zoom-in">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-black-900 uppercase font-bold">{t('faq.title')}</h2>
                    <div className='flex justify-center'>
                        <div className='w-48 border-b-4 border-red-900'></div>
                    </div>
                </div>

                <div className="max-w-5xl mx-auto">
                    <div className="grid sm:grid-cols-2 gap-6 md:gap-12">
                        <div>
                            <h3 className="text-lg font-semibold text-black-800">
                                {t('faq.q1')}
                            </h3>
                            <p className="mt-2 text-gray-900 dark:text-gray-400 text-justify">
                                {t('faq.a1')}
                            </p>
                        </div>

                        <div>
                            <h3 className="text-lg font-semibold text-black-800">
                                {t('faq.q2')}
                            </h3>
                            <p className="mt-2 text-gray-900 dark:text-gray-400 text-justify">
                                {t('faq.a2')}
                            </p>
                        </div>

                        <div>
                            <h3 className="text-lg font-semibold text-black-800">
                                {t('faq.q3')}
                            </h3>
                            <p className="mt-2 text-gray-900 dark:text-gray-400 text-justify">
                                {t('faq.a3')}
                            </p>
                        </div>

                        <div>
                            <h3 className="text-lg font-semibold text-black-800">
                                {t('faq.q4')}
                            </h3>
                            <p className="mt-2 text-gray-900 dark:text-gray-400 text-justify">
                                {t('faq.a4')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Faq;
