import React from 'react';
import { useTranslation } from 'react-i18next';

const Services = () => {
    const { t, i18n } = useTranslation();
    const index = i18n.language;

    return (
        <>
            <div dir={index === 'ar' ? 'rtl' : 'ltr'}>
                <div className="py-12 bg-gray-100" id='Histoirearmee'>
                    <div>
                        <h2 className="my-2 text-center text-3xl text-black-900 uppercase font-bold">{t('histoire.bigtitle')}</h2>
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-red-900 mb-8'></div>
                        </div>
                        <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                            <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-5">
                                <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max flex flex-col justify-between">
                                    <div className="m-2 text-justify text-sm flex-grow">
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4">
                                            {t('histoire_culture1.titre_item')}
                                        </h4>
                                        <p className="text-md font-medium leading-5 mb-4">
                                            {t('histoire_culture1.paragraph_item')}
                                        </p>
                                    </div>
                                    <div className="flex justify-center my-4">
                               
                                    </div>
                                </div>

                                <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max flex flex-col justify-between">
                                    <div className="m-2 text-justify text-sm flex-grow">
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4">
                                            {t('histoire_culture2.titre_item')}
                                        </h4>
                                        <p className="text-md font-medium leading-5 mb-4">
                                            {t('histoire_culture2.paragraph_item')}
                                        </p>
                                    </div>
                                    <div className="flex justify-center my-4">
                                  
                                    </div>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Services;







































