import React from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import cem from '../images/images_departement/11.png';
import emerg from '../images/images_departement/111.jpg';
import csd from '../images/images_departement/2233.png';
import crerd from '../images/images_departement/333.png';


const Departements = () => {
    const { t,i18n } = useTranslation();
    const index = i18n.language;
 
    

  

    return (
        <div dir={index === 'ar' ? 'rtl' : 'ltr'}>
        <div id="departements" className="bg-gray-100 py-12">
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-5xl text-black-900 uppercase font-bold">
                        
                        {t('structures.nom')}  
                    </h2>
                    
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-6 border-yellow-900'></div>
                    </div>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="400">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                            <HashLink   className="block transition-all ease-in-out duration-400">
                                <div className="bg-white overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                    <div className="m-2 text-justify text-sm">
                                        <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out mx-auto" src={crerd} />
                                        <h2 className="font-semibold my-4 text-2xl text-center">
                                            {t('departement_crerd.titre_depar')}
                                        </h2>
                                        <p className="text-md font-medium">
                                            {t('departement_crerd.enonce_depar')}
                                        </p>
                                    </div>
                                </div>
                            </HashLink>

                            <HashLink  className="block transition-all ease-in-out duration-400">                                
                            <div className="bg-white overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                             <div className="m-2 text-justify text-sm">
                             <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out mx-auto" src={cem} />
                             <h2 className="font-semibold my-4 text-2xl text-center">
                             {t('departement_cem.titre_depar')}                                       
                            </h2>
                            <p className="text-md font-medium">
                            {t('departement_cem.enonce_depar')}

                            </p>
                            </div>
                            </div>
                            </HashLink> 

                            <HashLink  className="block transition-all ease-in-out duration-400">                                
                            <div className="bg-white overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                            <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out mx-auto" src={csd} />
                            <h2 className="font-semibold my-4 text-2xl text-center">
                            {t('departement_csd.titre_depar')}                
                            </h2>                                     
                            <p className="text-md font-medium">
                            {t('departement_csd.enonce_depar')}
                            </p>
                            </div>
                            </div>
                            </HashLink> 
                            <HashLink   className="block transition-all ease-in-out duration-400">
                                <div className="bg-white overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                    <div className="m-2 text-justify text-sm">
                                        <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out mx-auto" src={emerg} />
                                        <h2 className="font-semibold my-4 text-2xl text-center">
                                            {t('Embedded Systems')}
                                        </h2>
                                        <p className="text-md font-medium">
                                            {t('Systeme_emergents.enonce_depar')}
                                        </p>
                                    </div>
                                </div>
                            </HashLink>
                            
                        
                    </div>
                </div>
            </section>
        </div>
         </div>
    );
}

export default Departements;


























