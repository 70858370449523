import React from 'react';
import Footer from './Footer';
import NavBar from './Navbar/NavBar';

const Layout = ({ children }) => {
  return (
<div className="flex flex-col min-h-screen">     
 <NavBar/>

 <main className="flex-grow">
    {children}
    </main>      
    <Footer />
 </div>
  );
};

export default Layout;
