import 'primeicons/primeicons.css';
import { Dropdown } from 'primereact/dropdown';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import '../CaptionDemo.css';

const NavLinks = ({ onLinkClick }) => {
    const { i18n } = useTranslation();
    const { t } = useTranslation();

    const [isEventSubMenuOpen, setIsEventSubMenuOpen] = useState(false);
    const [isDepartmentSubMenuOpen, setIsDepartmentSubMenuOpen] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const handleEventClick = () => {
        setIsEventSubMenuOpen(!isEventSubMenuOpen);
        if (!isEventSubMenuOpen) {
            setIsDepartmentSubMenuOpen(false);
        }
    };

    const handleDepartmentClick = () => {
        setIsDepartmentSubMenuOpen(!isDepartmentSubMenuOpen);
        if (!isDepartmentSubMenuOpen) {
            setIsEventSubMenuOpen(false);
        }
    };

    const handleItemClick = () => {
        onLinkClick();
        setTimeout(() => {
            setIsEventSubMenuOpen(false);
            setIsDepartmentSubMenuOpen(false);
        }, 300);
    };

    const languageOptions = [
        { label: 'English', value: 'en' },
        { label: 'Français', value: 'fr' },
        { label: 'العربية', value: 'ar' }
    ];
    const selectedLanguageLabel = languageOptions.find(option => option.value === i18n.language)?.label;

    return (
        <> 


        <div className="flex flex-col lg:flex-row lg:items-center lg:space-x-4">
                <Dropdown
                    value={i18n.language}
                    options={languageOptions}
                    onChange={(e) => changeLanguage(e.value)}
                    optionLabel="label"
                    placeholder={selectedLanguageLabel}
                    className="custom-dropdown language-dropdown mb-4 lg:mb-0"
                    dropdownIcon="pi pi-globe" />

                <HashLink className="block lg:inline-block px-4 py-2 font-extrabold text-black-500 hover:text-green-900" smooth to="/" onClick={onLinkClick}>
                    {t('navbar.home')}
                </HashLink>
                <div className="relative lg:inline-block text">
                    <button className="block lg:inline-block px-4 py-2 font-extrabold text-black-500 hover:text-blue-900 cursor-pointer" onClick={handleEventClick}>
                        <span>{t('navbar.events')}</span>
                        <i className="pi pi-chevron-down ml-1"></i>
                    </button>
                    {isEventSubMenuOpen && (
                        <div className="absolute z-20 mt-1 w-full lg:w-48 bg-white shadow-lg rounded-lg overflow-hidden">
                            <HashLink className="block px-4 py-2 text-black-800 hover:bg-black-200" to="/whyrevodeac" onClick={handleItemClick}>
                                {t('navbar.directorMessage')}
                            </HashLink>
                        </div>
                    )}
                </div>
                <div className="relative lg:inline-block text mt-2 lg:mt-0">
                    <HashLink
                        smooth
                        to="/#Services"
                        className="block lg:inline-block px-4 py-2 font-extrabold text-black-500 hover:text-blue-900 cursor-pointer"
                        onClick={handleDepartmentClick}>
                        {t('navbar.structures')}
                    </HashLink>
                </div>
                <HashLink className="block lg:inline-block px-4 py-2 font-extrabold text-black-500 hover:text-red-900" to="/contact#contact" onClick={onLinkClick}>
                    {t('navbar.contact')}
                </HashLink>
            </div></>
    );
};

export default NavLinks;
