import React from 'react';
import Departements from '../components/Departements';
import Hero from '../components/Hero';
import Hero2 from '../components/Hero2';
import Services from '../components/Services';
import Faq from '../components/faq';
import StatisticsSection from '../components/satique';
const Home = () => {
  
    return (
        <>
            <Hero2 />
            <Hero />
            <Departements />
            <Services/>
           
            <Faq  />
            {/* <Galery /> */}
            {/* <Blog /> */}
            <StatisticsSection />

            {/* <Partenariats /> */}
        </>
    );
};

export default Home;
