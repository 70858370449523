import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import loc from '../../images/images_navbar/loc.png';
import otherImage from '../../images/images_navbar/logo.png';
import NavLinks from '../Navbar/NavLinks';
const NavBar = () => {
    const {i18n } = useTranslation();
    const [top, setTop] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const scrollHandler = () => {
            window.pageYOffset > 10 ? setTop(false) : setTop(true);
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);

    const isRTL = i18n.language === 'ar';

    return (
        <nav className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out mb-8 bg-white  ${!top ? 'shadow-lg shadow-red-50' : ''}`}>
        
        
            <div className={`flex flex-col lg:flex-row justify-between items-center py-2 px-4 `}>
                <div className="flex justify-between items-center w-full lg:w-auto">
                    {/* <img src={img} alt="Nom de votre entreprise" style={{ width: '80px' }} /> */}
                    <div><img src={otherImage} className="h-24 w-24 rounded-full" alt="Circular1" /> </div>
                    <div><img src={loc} className="h-24 w-24 rounded-full" alt="Circular1" /> </div>
        <div className='text-md font-medium text-blue-900'>
            
            <h2>  Lot Annasr Al Moustakbal Ave Tarik Ibn Ziad Imm B Apt 1, Temara,12000, Morocco</h2>
            <h2>  +212651036941</h2>
            <h2>  contact@revodevac.com</h2>

        </div>
                    <div className="lg:hidden">
                        
                        <button className="p-2 rounded-lg text-blue-900" onClick={handleClick}>
                            <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                {isOpen ? (
                                    <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                                ) : (
                                    <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2z" />
                                )}
                            </svg>
                        </button>
                    </div>
                </div>
                <div className={`w-full lg:w-auto ${isOpen ? 'block' : 'hidden'} lg:flex lg:flex-row lg:space-x-6`}   style={isRTL ? { direction: 'rtl' } : {}}  >
                    <NavLinks onLinkClick={() => setIsOpen(false)} className="flex flex-col lg:flex-row lg:space-x-6" />
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
