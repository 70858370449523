import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
    const location = useLocation();

    useEffect(() => {
        // Check if we're on the home page and there is a hash in the URL
        if (location.pathname === "/" && location.hash === "#Services") {
            // Remove the '#' from the hash and scroll to the element with the ID
            const elementId = location.hash.substring(1); // This will be 'histoireetculturee'
            const element = document.getElementById(elementId);

            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            // Scroll to the top of the page for other cases
            window.scrollTo({ top: 10, left: 0, behavior: 'smooth' });
        }
    }, [location.pathname, location.hash]); // Trigger effect on pathname or hash change

    return <>{props.children}</>;
};

export default ScrollToTop;
