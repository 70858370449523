import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import heroImg from '../images/images_navbar/logo.png';
import Loading from './Loading';

const Hero = () => {
    const [heroData, setHeroData] = useState([]);
    const { t, i18n } = useTranslation();
    const index = i18n.language;
    console.log("nnnn",index)

    useEffect(() => {
        const data = t('hero', { returnObjects: true });
        setHeroData(data);
    }, [t]);

    if (!heroData) {
        return <Loading />;
    }

    return (
        <>
        <div dir={index === 'ar' ? 'rtl' : 'ltr'}>
            <div className="hero" id='hero'>
                <div style={{ margin: '50px' }} className="flex justify-center items-center" data-aos="zoom-in">
                    {/* <img alt="card img" className="rounded-t duration-1000" src={heroImg} style={{ width: "400px" }} /> */}
                    <div><img src={heroImg} className="h-100 w-100 rounded-full" alt="Circular1" /> </div>
                </div>
                <div className="m-auto mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">
                    <div id='hero' className={`flex flex-col lg:flex-row py-8 justify-between text-center ${index === 'ar' ? 'lg:text-right' : 'lg:text-left'}`}>
                        <div className="flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
                            <h8 className="mb-5 md:text-2xl text-3xl font-bold text-blue-900 text-center custom-font">
                                {/* {heroData.titre_college} */}
                            
                            
                                {heroData.Contenu_college}
                                </h8>
                            </div>
                            {/* <div className="flex justify-center">
                                <HashLink to="/histoireCrems" className="inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg text-white bg-green-900 hover:bg-yellow-900 shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    {heroData.button}
                                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path>
                                    </svg>
                                </HashLink>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        {/* </div> */}
        </>
    );
}

export default Hero;
