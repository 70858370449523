import 'primeicons/primeicons.css';
import { Card } from 'primereact/card';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import React from 'react';
import contact from '../images/images_contact/Contact-crems11.webp';
import './CaptionDemo.css';


const Contact = () => {
    
    return (
        <div  style={{ margin: '120px auto' }} className="contact-container"  data-aos="flip-left" >
            <div className="contact-image">
                <img src={contact} alt="Contact Banner" />
            </div>
            <div className="contact-info-container">
                <Card className="contact-card">
                    <h2 className='text-3xl'>Contact Us</h2>
                    <div className="contact-info">
                        <div className="contact-item">
                            <i className="pi pi-envelope"></i>
                            <span>Email: contact@revodevac.com</span>
                        </div>
                        <div className="contact-item">
                            <i className="pi pi-phone"></i>
                            <span>Phone: +212687-990-5042 </span>
                        </div>
                        <div className="contact-item">
                            <i className="pi pi-facebook"></i>
                            <span>Facebook: https://web.facebook.com</span>
                        </div>
                        
                    </div>
                    </Card>
            </div>
        </div>
    );
};

export default Contact;
































