import React from 'react';
import { useTranslation } from 'react-i18next';
const StatisticsSection = () => {
    const { t,i18n } = useTranslation();
  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center space-y-4">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            {t('static.titre1')}
            </h2>
            <p className="text-lg leading-8 text-gray-300">
            {t('static.titre2')}
            </p>
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
            <div className="flex flex-col bg-white/5 p-8">
              <dt className="text-sm font-semibold leading-6 text-gray-300">
                Softwares
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-white">
                35
              </dd>
            </div>
            <div className="flex flex-col bg-white/5 p-8">
              <dt className="text-sm font-semibold leading-6 text-gray-300">
                Embedded Systemes
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-white">
                28
              </dd>
            </div>
            <div className="flex flex-col bg-white/5 p-8">
              <dt className="text-sm font-semibold leading-6 text-gray-300">
                Web Apps
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-white">
                40
              </dd>
            </div>
            <div className="flex flex-col bg-white/5 p-8">
              <dt className="text-sm font-semibold leading-6 text-gray-300">
                 Mobile Apps
              </dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-white">
                25
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default StatisticsSection;
