

import React, { useEffect, useState } from 'react';
import ecoles from '../images/images_hero2/ecole.jpg';
import langley from '../images/images_hero2/pharrrr.png';
import Prince from '../images/images_hero2/resss.png';
import france from '../images/images_hero2/sporttttttt.jpg';
import china2 from '../images/images_hero2/usine.jpg';
import './style.css';





import { useTranslation } from 'react-i18next';

const Hero2 = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? 4 : prevIndex - 1));
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex === 4 ? 0 : prevIndex + 1));
  };

  const handleIndicatorClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div id="carouselExampleCaptions" className="relative " data-twe-carousel-init data-twe-ride="carousel" style={{ marginTop: '0', paddingTop: '0' }} >
      <div className="absolute bottom-0 left-0 right-0 z-[2] mx-[15%] mb-4 flex list-none justify-center p-0" data-twe-carousel-indicators>
        {[0, 1, 2, 3, 4,].map((index) => (
          <button
            key={index}
            type="button"
            data-twe-target="#carouselExampleCaptions"
            data-twe-slide-to={index}
            className={`mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none ${
              activeIndex === index ? 'bg-white opacity-100' : 'bg-gray-500 opacity-50'
            }`}
            aria-label={`Slide ${index + 1}`}
            onClick={() => handleIndicatorClick(index)}
          />
        ))}
      </div>

      <div className="carousel-inner" style={{ margin: '100px auto' }}>
      <div className="carousel-item" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
  <img src={Prince} className="block w-full h-[400px] md:h-[630px] object-cover" alt="First slide" />
  <div className="absolute inset-x-[15%] bottom-5 py-5 text-center text-white">
    <h5
      className="text-base md:text-3xl italic"
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
        padding: '10px', // Add some padding for better appearance
      }}
    >
      {t('hero2.titre1')}
    </h5>
  </div>
</div>

        <div className="carousel-item" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
          <img src={ecoles} className="block w-full h-[400px] md:h-[630px] object-cover" alt="Second slide" />
          <div className="absolute inset-x-[15%] bottom-5 py-5 text-center text-white">
            <h5  className="text-base md:text-3xl "
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
        padding: '10px', // Add some padding for better appearance
      }}>{t('hero2.titre2')}</h5>
          </div>
        </div>

        <div className="carousel-item" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
          <img src={langley} className="block w-full h-[400px] md:h-[630px] object-cover" alt="Third slide" />
          <div className="absolute inset-x-[15%] bottom-5 py-5 text-center text-white">
            <h5  className="text-base md:text-3xl "
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
        padding: '10px', // Add some padding for better appearance
      }}>{t('hero2.titre3')}</h5>
          </div>
        </div>
        <div className="carousel-item" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
          <img src={china2} className="block w-full h-[400px] md:h-[630px] object-cover" alt="Third slide" />
          <div className="absolute inset-x-[15%] bottom-5 py-5 text-center text-white">
            <h5  className="text-base md:text-3xl "
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
        padding: '10px', // Add some padding for better appearance
      }}> {t('hero2.titre4')}</h5>
          </div>
        </div>


        <div className="carousel-item" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
          <img src={france} className="block w-full h-[400px] md:h-[630px] object-cover" alt="Fourth slide" />
          <div className="absolute inset-x-[15%] bottom-5 py-5 text-center text-white">
            <h5  className="text-base md:text-3xl "
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
        padding: '10px', // Add some padding for better appearance
      }}>{t('hero2.titre5')}</h5>
          </div>
        </div>
       
      </div>

      <button
        className="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
        type="button"
        data-twe-target="#carouselExampleCaptions"
        data-twe-slide="prev"
        onClick={handlePrev}
      >
        <span className="inline-block h-8 w-8">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </span>
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Previous</span>
      </button>

      <button
        className="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
        type="button"
        data-twe-target="#carouselExampleCaptions"
        data-twe-slide="next"
        onClick={handleNext}
      >
        <span className="inline-block h-8 w-8">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        </span>
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Next</span>
      </button>
    </div>
  );
};

export default Hero2;
